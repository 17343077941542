/**
 * Created by yaoandw on 2017/3/12.
 */
import axios from 'axios';
import sess from './sess';

var HTTP = createHTTP()

function getHTTP() {
  return HTTP
}

function refreshHTTP() {
  HTTP = createHTTP()
}

function createHTTP() {
  console.log('constants.host:'+process.env.VUE_APP_HOST+',constants.port:'+process.env.VUE_APP_PORT)
  HTTP = axios.create({
    baseURL:process.env.VUE_APP_PROTOCOL + '://'+process.env.VUE_APP_HOST+':'+process.env.VUE_APP_PORT,
    rejectUnauthorized: false,
    timeout: 30000,
    headers: {
      // Authorization: 'Bearer '+sess.get('accessToken')
      'authorization': sess.get('accessToken'),
    },
  })
  return HTTP
}

// export const HTTP = axios.create({
//   // baseURL: 'http://vs.jojo.la',
//   // baseURL: process.env.NODE_ENV === 'electron' ? 'http://localhost/' : (process.env.NODE_ENV === 'production' ? 'http://www.jojo.la:8088':'http://localhost:5555/'),
//   // baseURL: process.env.NODE_ENV === 'electron' ? 'http://www.jojo.la:8088' : (process.env.NODE_ENV === 'production' ? 'http://www.jojo.la:8088':'http://localhost:5555/'),
//   // baseURL: process.env.NODE_ENV === 'electron' ? 'http://www.jojo.la:8088' : (process.env.NODE_ENV === 'production' ? 'http://vs.jojo.la:8088':'http://localhost:5555/'),
//   baseURL:'http://'+constants.host+':'+constants.port,
//   rejectUnauthorized: false,
//   timeout: 4000,
//   headers: {
//         // Authorization: 'Bearer '+sess.get('accessToken')
//     'authorization': sess.get('accessToken'),
//   },
// });

// export function axiosInstance() {
//     console.info(HTTP);
//     HTTP.defaults.headers.common['Authorization'] = sess.get('accessToken');
//     return HTTP;
// }

export default {
  getHTTP,
  refreshHTTP
};
