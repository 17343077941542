import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '',
            name: 'qrLogin',
            component: () => import('../components/QrLogin')
        },{
            path: '/login',
            name: 'Login',
            component: () => import('../components/Login')
        },{
            path: '/ticket-login',
            name: 'TicketLogin',
            component: () => import('../components/TicketLogin')
        },{
            path: '/mark-list',
            name: 'MarkList',
            component: () => import('../components/mark/MarkList')
        },{
            path: '/mark-edit',
            name: 'MarkEdit',
            component: () => import('../components/mark/MarkEdit')
        },{
            path: '/init-data',
            name: 'InitData',
            component: () => import('../components/InitData')
        },
        // 默认配置
        {
            path: '/*',
            redirect: '/'
        }
    ]
})
