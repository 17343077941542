import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.use({router})

//http util
import httpUtil from './common/httpUtil'
Vue.prototype.$httpUtil = httpUtil

//session util
import sess from './common/sess'
Vue.prototype.$sess = sess

//constant
import urlConstant from './common/urlConstant'
Vue.prototype.$urlConstant = urlConstant

//date format
import {formatDate} from "./common/utils/dateUtil";
Vue.filter('formatDate',function (time) {
    if (time) {
        // var date = new Date(time);
        return formatDate(time, 'yyyy-MM-dd hh:mm:ss');
    }
})
Vue.filter('formatTime',function (time) {
    if (time) {
        // var date = new Date(time);
        return formatDate(time, 'hh:mm:ss');
    }
})

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
