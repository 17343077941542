export default {
  get(name) {
    let value = sessionStorage.getItem(name);
    if (/^\{.*\}$/.test(value)) {
      value = JSON.parse(value);
    }
    return value;
  },
  set(name, value) {
    let v = value;
    if (typeof v === typeof {}) {
      v = JSON.stringify(v);
    }
    return sessionStorage.setItem(name, v);
  },
  remove(name) {
    return sessionStorage.removeItem(name);
  },
  login(responseData) {
    this.set('accessToken',responseData.data.tn);
    this.set('userId', responseData.data.userId);
    this.set('username', responseData.data.username);
    this.set('nickname', responseData.data.nickname);
    this.set('roles', responseData.data.roles);
    this.set('siteId', responseData.data.siteId);
  },
  logout() {
    this.remove('accessToken')
    this.remove('userId')
    this.remove('username')
    this.remove('roles')
    this.remove('siteId')
    this.remove('nickname')
  }
};
